<template>
  <page :title="role" :subtitle="companyName"
    :imageUrl="imageUrl" :apiStatusList="[fetchJobViewStatus]">
    <template v-slot:subtitle v-if="true">
      <v-row class="pl-5">
        <v-col>
            {{ companyName }}
        </v-col>
      </v-row>
    </template>

    <v-card v-if="!loading" class="pl-16 py-6" elevation="0">
      <!-- <v-card-title class="text-h5 justify-space-between">
        <div class="d-flex flex-row">
          <v-avatar color="primary" size="80">
            <v-img :src="jobMetaData?.getCompanyLogoUrl()"></v-img>
          </v-avatar>
          <div class="d-flex flex-column ml-5">
            <div class="font-weight-medium text-h4">{{ currentJobData?.getRole() }}</div>
            <div class="text-body-1 pt-2">
              <span>
                {{ jobMetaData?.getCompanyName() }}
              </span>
              <span>
                <v-icon color="black">{{ mdiMapMarker }}</v-icon>
              </span>
              <span>
                {{ jobMetaData?.getLocationList()?.join(", ") }}
              </span>
            </div>
          </div>
        </div>
      </v-card-title> -->
      <v-card-text>
        <v-row>
          <v-col cols="7">
            <div class="text-h6 font-weight-bold black--text pt-5">
              Description
            </div>
            <div>
              <html-view :html="currentJobData?.getAbout()"></html-view>
            </div>
            <div class="text-h6 font-weight-bold black--text pt-5">Skills</div>
            <div>
              <v-chip-group column>
                <v-chip v-for="(skill, idx) in currentJobData?.getSkillsList()" :key="idx" color="success">
                  {{ skill }}
                </v-chip>
              </v-chip-group>
            </div>
            <div class="text-h6 font-weight-bold black--text pt-5">
              Other Benefits
            </div>
            <ul>
              <li v-for="benefit in jobCtc?.getOtherBenefitsList()" :key="benefit">
                {{ benefit }}
              </li>
            </ul>
          </v-col>
          <v-col cols="3">
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiSeat }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobTypePretty(this.jobTypes[jobMetaData?.getJobType()])}}
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiBriefcase }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobPref?.join(", ") }}
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiMapMarker }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobMetaData?.getLocationList()?.join(", ") }}
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiFormatListNumbered }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                {{ jobMetaData?.getMinExperience() }}-{{
                    jobMetaData?.getMaxExperience()
                }}
                years
              </span>
            </div>
            <div class="pb-5">
              <span>
                <v-icon color="black">{{ mdiCurrencyInr }}</v-icon>
              </span>
              <span class="text-body-1 font-weight-medium">
                ₹{{ jobCtc?.getMinCtc()?.toLocaleString() }}-{{
                    jobCtc?.getMaxCtc().toLocaleString()
                }}
                <span class="text-caption gray--text"> per Annum </span>
              </span>
            </div>
            <!-- file upload code starts here -->
            <v-file-input :rules="rules" accept=".pdf" label="Attach resume PDF file" hint="Resume PDF" persistent-hint
              outlined prepend-icon="" :prepend-inner-icon="mdiAttachment" v-model="resumePdf">
            </v-file-input>
            <!-- file upload ends here	 -->
            <v-btn color="success" elevation="0" class="text-none text-body-1" @click="applyToJobListing"
              :disabled="alreadyAppliedToJob || !resumePdf" :loading="applying" :outlined="alreadyAppliedToJob">
              {{ alreadyAppliedToJob ? "Applied" : "Apply Now" }}
            </v-btn>
            <div>**Resume is required</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </page>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import HtmlView from "@/components/HtmlView.vue";
import Page from "../Page";

import {
  mdiFormatListNumbered,
  mdiDotsHorizontal,
  mdiCurrencyInr,
  mdiMapMarker,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiBriefcase,
  mdiWallet,
  mdiClose,
  mdiSeat,
  mdiAttachment,
} from "@mdi/js";
export default {
  props: {
    jobUrl: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    rules: [
      (v) => !!v || "File is required",
      (v) => (v && v.size > 0) || "File is required",
    ],
    resumePdf: null,
    mdiMapMarker,
    mdiBookmark,
    mdiBookmarkOutline,
    mdiBriefcase,
    mdiSeat,
    mdiDotsHorizontal,
    mdiCurrencyInr,
    mdiWallet,
    mdiFormatListNumbered,
    mdiClose,
    mdiAttachment,
    applying: false,
    alreadyAppliedToJob: undefined,
    loading: false,
  }),
  computed: {
    ...mapState("candidate", ["currentJob", "resumeResponse", "savedJobs", "fetchJobViewStatus"]),
    ...mapState("user", ["user"]),
    ...mapGetters("candidate", ["jobTypes", "jobPrefs"]),
    currentJobData() {
      console.log("Job View modal loading");
      return this.currentJob?.getDataView()?.getJobView();
    },
    jobMetaData() {
      return this.currentJob?.getMetaData()?.getJobMeta();
    },
    jobCtc() {
      return this.jobMetaData?.getCtc();
    },
    jobPref() {
      return this.jobMetaData
        ?.getPreferenceList()
        .map((v) => this.jobPrefs[v]);
    },
    role() {
      return this.currentJob?.getDataView()?.getJobView().getRole();
    },
    companyName() {
      return this.currentJob?.getMetaData()?.getJobMeta().getCompanyName();
    },
    imageUrl() {
      if(this.currentJob?.getMetaData()?.getJobMeta()?.getCompanyLogoUrl()) {
        return this.currentJob?.getMetaData()?.getJobMeta()?.getCompanyLogoUrl();
      }
    }
  },
  watch: {
  },
  methods: {
    ...mapActions("candidate", [
      "fetchJobView",
      "checkJobApplied",
      "applyToJob",
      "fetchResume",
      "saveJob", //TODO: add option from this page to save job
    ]),
    jobTypePretty(job) {
      console.log("Job is ..", job)
      if(job === 'FULL_TIME') return 'Full Time';
      if(job === 'INTERNSHIP') return 'Internship';
      if(job === 'CONTRACT') return 'Contract';
      else return "NA";
    },
    fetchViewingJob(url) {
      console.log(url);
      this.loading = true;
      this.fetchJobView({ url }).then(() => {
        if (this.user) {
          var id = this.currentJob.getId();
          console.log("JobId is ..", id)
          this.checkJobApplied({ id }).then((res) => {
            this.alreadyAppliedToJob = res?.getApplied();
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    },
    async applyToJobListing() {
      console.log("Applying ..", this.resumePdf);
      this.applying = true;
      if (!this.user) {
        this.$store.dispatch("notifs/addNotif", {
          text: "Login before applying to job",
          type: "error",
        });
        this.applying = false;
        return;
      }
      if (!this.resumePdf) {
        this.$store.dispatch("notifs/addNotif", {
          text: "Please upload resume",
          type: "error",
        });
        this.applying = false;
        return;
      }
      await this.resumePdf
        .arrayBuffer()
        .then((content) => {
          this.applyToJob({
            id: this.currentJob.getId(),
            resume: new Uint8Array(content),
            name: this.resumePdf.name,
            type: this.resumePdf.type,
          }).then(() => {
            this.$store.dispatch("notifs/addNotif", {
              text: "Successfully applied to job!",
              type: "success",
            });
            this.applying = false;
            this.alreadyAppliedToJob = true;
          });
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: "applyToJob: Error applying to job! Please try again later!",
            type: "error",
          });
          this.applying = false;
        });
    },
  },
  components: {
    Page,
    Loader,
    HtmlView,
  },
  mounted() {
    this.fetchViewingJob(this.jobUrl);
    console.log("This is jobview");
  },
};
</script>