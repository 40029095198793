var render = function () {
  var _vm$currentJobData, _vm$currentJobData2, _vm$jobCtc, _vm$jobMetaData, _vm$jobPref, _vm$jobMetaData2, _vm$jobMetaData2$getL, _vm$jobMetaData3, _vm$jobMetaData4, _vm$jobCtc2, _vm$jobCtc2$getMinCtc, _vm$jobCtc3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": _vm.role,
      "subtitle": _vm.companyName,
      "imageUrl": _vm.imageUrl,
      "apiStatusList": [_vm.fetchJobViewStatus]
    },
    scopedSlots: _vm._u([true ? {
      key: "subtitle",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pl-5"
        }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.companyName) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [!_vm.loading ? _c('v-card', {
    staticClass: "pl-16 py-6",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "text-h6 font-weight-bold black--text pt-5"
  }, [_vm._v(" Description ")]), _c('div', [_c('html-view', {
    attrs: {
      "html": (_vm$currentJobData = _vm.currentJobData) === null || _vm$currentJobData === void 0 ? void 0 : _vm$currentJobData.getAbout()
    }
  })], 1), _c('div', {
    staticClass: "text-h6 font-weight-bold black--text pt-5"
  }, [_vm._v("Skills")]), _c('div', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l((_vm$currentJobData2 = _vm.currentJobData) === null || _vm$currentJobData2 === void 0 ? void 0 : _vm$currentJobData2.getSkillsList(), function (skill, idx) {
    return _c('v-chip', {
      key: idx,
      attrs: {
        "color": "success"
      }
    }, [_vm._v(" " + _vm._s(skill) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "text-h6 font-weight-bold black--text pt-5"
  }, [_vm._v(" Other Benefits ")]), _c('ul', _vm._l((_vm$jobCtc = _vm.jobCtc) === null || _vm$jobCtc === void 0 ? void 0 : _vm$jobCtc.getOtherBenefitsList(), function (benefit) {
    return _c('li', {
      key: benefit
    }, [_vm._v(" " + _vm._s(benefit) + " ")]);
  }), 0)]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiSeat))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.jobTypePretty(this.jobTypes[(_vm$jobMetaData = _vm.jobMetaData) === null || _vm$jobMetaData === void 0 ? void 0 : _vm$jobMetaData.getJobType()])) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiBriefcase))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$jobPref = _vm.jobPref) === null || _vm$jobPref === void 0 ? void 0 : _vm$jobPref.join(", ")) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiMapMarker))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$jobMetaData2 = _vm.jobMetaData) === null || _vm$jobMetaData2 === void 0 ? void 0 : (_vm$jobMetaData2$getL = _vm$jobMetaData2.getLocationList()) === null || _vm$jobMetaData2$getL === void 0 ? void 0 : _vm$jobMetaData2$getL.join(", ")) + " ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiFormatListNumbered))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$jobMetaData3 = _vm.jobMetaData) === null || _vm$jobMetaData3 === void 0 ? void 0 : _vm$jobMetaData3.getMinExperience()) + "-" + _vm._s((_vm$jobMetaData4 = _vm.jobMetaData) === null || _vm$jobMetaData4 === void 0 ? void 0 : _vm$jobMetaData4.getMaxExperience()) + " years ")])]), _c('div', {
    staticClass: "pb-5"
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.mdiCurrencyInr))])], 1), _c('span', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(" ₹" + _vm._s((_vm$jobCtc2 = _vm.jobCtc) === null || _vm$jobCtc2 === void 0 ? void 0 : (_vm$jobCtc2$getMinCtc = _vm$jobCtc2.getMinCtc()) === null || _vm$jobCtc2$getMinCtc === void 0 ? void 0 : _vm$jobCtc2$getMinCtc.toLocaleString()) + "-" + _vm._s((_vm$jobCtc3 = _vm.jobCtc) === null || _vm$jobCtc3 === void 0 ? void 0 : _vm$jobCtc3.getMaxCtc().toLocaleString()) + " "), _c('span', {
    staticClass: "text-caption gray--text"
  }, [_vm._v(" per Annum ")])])]), _c('v-file-input', {
    attrs: {
      "rules": _vm.rules,
      "accept": ".pdf",
      "label": "Attach resume PDF file",
      "hint": "Resume PDF",
      "persistent-hint": "",
      "outlined": "",
      "prepend-icon": "",
      "prepend-inner-icon": _vm.mdiAttachment
    },
    model: {
      value: _vm.resumePdf,
      callback: function callback($$v) {
        _vm.resumePdf = $$v;
      },
      expression: "resumePdf"
    }
  }), _c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "color": "success",
      "elevation": "0",
      "disabled": _vm.alreadyAppliedToJob || !_vm.resumePdf,
      "loading": _vm.applying,
      "outlined": _vm.alreadyAppliedToJob
    },
    on: {
      "click": _vm.applyToJobListing
    }
  }, [_vm._v(" " + _vm._s(_vm.alreadyAppliedToJob ? "Applied" : "Apply Now") + " ")]), _c('div', [_vm._v("**Resume is required")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }